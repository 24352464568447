<template>
  <div
    class="form-group last:mb-0"
    :class="margins ? 'mb-5' : ''"
  >
    <div>
      <label
        v-if="label"
        class="block text-sm font-medium leading-5"
        :for="name"
        :class="{
          'text-red-400': error,
          'text-blue-gray-700': !error,
        }"
      >
        {{ label }}
      </label>
    </div>
    <div :class="margins ? 'mt-1' : ''">
      <div
        class="flex flex-1 rounded-lg relative"
        :class="withShadow ? 'shadow-sm' : ''"
      >
        <slot />
        <div
          v-if="error"
          class="pr-3 flex items-center absolute inset-y-0 right-0"
        >
          <span class="h-5 w-5 text-blue-gray-400 relative">
            <span class="absolute inset-y-0 right-0 flex items-center pointer-events-none">
              <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
            </span>
          </span>
        </div>
      </div>
      <p
        v-if="error"
        class="mt-1 text-red-400 text-sm"
      >
        {{ error }}
      </p>
      <p
        v-if="hasHelpText"
        :id="`${inputId}-description`"
        class="form-help mt-2 text-sm text-blue-gray-500"
      >
        <slot name="helpText" />
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import ErrorService from '@/Services/ErrorService';

const props = withDefaults(defineProps<{
  label?: string,
  name: string,
  inputId?: string,
  withShadow?: boolean,
  errorProvider?: string,
  margins?: boolean,
}>(), {
  withShadow: true,
  errorProvider: 'inertia',
  margins: true,
});

const errorService = new ErrorService(props.errorProvider);

const error = computed(() => errorService.getError(props.name) ?? null);
// eslint-disable-next-line no-prototype-builtins
const hasHelpText = computed(() => getCurrentInstance()?.slots.hasOwnProperty('helpText'));
</script>
